var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect"
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src:
                      "http://zzl-onlinecourse.oss-cn-beijing.aliyuncs.com/c2652448-b8c5-4b22-a27e-1bc21e8ba62c.png?Expires=1952667855&OSSAccessKeyId=LTAIZUVbieSUWegn&Signature=Nlc41UViLdtc0%2Bc0t0Z8yG0KaL0%3D"
                  }
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/user/profile" } },
                    [_c("el-dropdown-item", [_vm._v("个人中心")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "修改信息",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "formTemplate",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "element-loading-background": "rgba(255, 255, 255, 255)",
                    model: _vm.formTemplate,
                    "label-width": "140px",
                    "label-position": "right"
                  }
                },
                [
                  _vm.dialogText == "password"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "密码：", prop: "password" } },
                        [
                          _c("el-input", {
                            staticClass: "'border_none'",
                            attrs: { placeholder: "", clearable: "" },
                            model: {
                              value: _vm.formTemplate.password,
                              callback: function($$v) {
                                _vm.$set(_vm.formTemplate, "password", $$v)
                              },
                              expression: "formTemplate.password"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.dialogUpdate()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }