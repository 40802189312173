import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "D:\\storeManage\\ui\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.array.iterator.js";
import "D:\\storeManage\\ui\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.promise.js";
import "D:\\storeManage\\ui\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es6.object.assign.js";
import "D:\\storeManage\\ui\\node_modules\\@vue\\babel-preset-app\\node_modules\\core-js\\modules\\es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon

import "./permission"; // permission control

import "./utils/error-log"; // error log

import * as filters from "./filters"; // global filters

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css'; //axios请求插件
// Vue.use(Viewer) 默认配置写法

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
});
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size

}); // register global utility filters

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});