exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#d9e8f7",
	"subMenuActiveText": "#3A71A8",
	"menuBg": "#409eff",
	"menuHover": "#2285ea",
	"subMenuBg": "#2285ea",
	"subMenuHover": "#2285ea",
	"sideBarWidth": "210px"
};