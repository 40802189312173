/** When your routing table is too long, you can split it into small modules **/
import Layout from '@/layout';
var componentsRouter = {
  path: '/components',
  component: Layout,
  redirect: 'noRedirect',
  name: 'ComponentDemo',
  meta: {
    title: 'Components',
    icon: 'component'
  },
  children: [{
    path: 'tinymce',
    component: function component() {
      return import('@/views/components-demo/tinymce');
    },
    name: 'TinymceDemo',
    meta: {
      title: 'Tinymce'
    }
  }, {
    path: 'markdown',
    component: function component() {
      return import('@/views/components-demo/markdown');
    },
    name: 'MarkdownDemo',
    meta: {
      title: 'Markdown'
    }
  }, {
    path: 'json-editor',
    component: function component() {
      return import('@/views/components-demo/json-editor');
    },
    name: 'JsonEditorDemo',
    meta: {
      title: 'JSON Editor'
    }
  }, {
    path: 'split-pane',
    component: function component() {
      return import('@/views/components-demo/split-pane');
    },
    name: 'SplitpaneDemo',
    meta: {
      title: 'SplitPane'
    }
  }, {
    path: 'avatar-upload',
    component: function component() {
      return import('@/views/components-demo/avatar-upload');
    },
    name: 'AvatarUploadDemo',
    meta: {
      title: 'Upload'
    }
  }, {
    path: 'dropzone',
    component: function component() {
      return import('@/views/components-demo/dropzone');
    },
    name: 'DropzoneDemo',
    meta: {
      title: 'Dropzone'
    }
  }, {
    path: 'sticky',
    component: function component() {
      return import('@/views/components-demo/sticky');
    },
    name: 'StickyDemo',
    meta: {
      title: 'Sticky'
    }
  }, {
    path: 'count-to',
    component: function component() {
      return import('@/views/components-demo/count-to');
    },
    name: 'CountToDemo',
    meta: {
      title: 'Count To'
    }
  }, {
    path: 'mixin',
    component: function component() {
      return import('@/views/components-demo/mixin');
    },
    name: 'ComponentMixinDemo',
    meta: {
      title: 'Component Mixin'
    }
  }, {
    path: 'back-to-top',
    component: function component() {
      return import('@/views/components-demo/back-to-top');
    },
    name: 'BackToTopDemo',
    meta: {
      title: 'Back To Top'
    }
  }, {
    path: 'drag-dialog',
    component: function component() {
      return import('@/views/components-demo/drag-dialog');
    },
    name: 'DragDialogDemo',
    meta: {
      title: 'Drag Dialog'
    }
  }, {
    path: 'drag-select',
    component: function component() {
      return import('@/views/components-demo/drag-select');
    },
    name: 'DragSelectDemo',
    meta: {
      title: 'Drag Select'
    }
  }, {
    path: 'dnd-list',
    component: function component() {
      return import('@/views/components-demo/dnd-list');
    },
    name: 'DndListDemo',
    meta: {
      title: 'Dnd List'
    }
  }, {
    path: 'drag-kanban',
    component: function component() {
      return import('@/views/components-demo/drag-kanban');
    },
    name: 'DragKanbanDemo',
    meta: {
      title: 'Drag Kanban'
    }
  }]
};
export default componentsRouter;