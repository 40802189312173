import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";
import componentsRouter from "./modules/components";
import chartsRouter from "./modules/charts";
import tableRouter from "./modules/table";
import nestedRouter from "./modules/nested";
Vue.use(Router);
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: "/redirect",
  component: Layout,
  hidden: true,
  children: [{
    path: "/redirect/:path(.*)",
    component: function component() {
      return import("@/views/redirect/index");
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return import("@/views/login/index");
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return import("@/views/login/auth-redirect");
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return import("@/views/error-page/404");
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return import("@/views/error-page/401");
  },
  hidden: true
}, {
  path: "/",
  component: Layout,
  redirect: "/dashboard",
  children: [{
    path: "dashboard",
    component: function component() {
      return import("@/views/dashboard/index");
    },
    name: "Dashboard",
    meta: {
      title: "首页",
      icon: "guide",
      affix: true
    }
  }]
}, {
  path: '/user',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'profile',
    component: function component(resolve) {
      return require(['@/views/user/profile/index'], resolve);
    },
    name: 'Profile',
    meta: {
      title: '个人中心',
      icon: 'user'
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

export var asyncRoutes = [{
  path: "/store",
  component: Layout,
  alwaysShow: true,
  name: "store",
  meta: {
    title: "店铺管理",
    icon: "tree"
  },
  children: [{
    path: "store",
    component: function component() {
      return import("@/views/store/index");
    },
    name: "store",
    meta: {
      title: "店铺管理",
      icon: "tree",
      affix: false
    }
  }]
}, {
  path: "/order",
  component: Layout,
  alwaysShow: true,
  name: "order",
  meta: {
    title: "订单管理",
    icon: "tree"
  },
  children: [{
    path: "order",
    component: function component() {
      return import("@/views/order/index");
    },
    name: "order",
    meta: {
      title: "订单管理",
      icon: "tree",
      affix: false
    }
  }]
}, {
  path: "/sysuser",
  component: Layout,
  alwaysShow: true,
  name: "sysuser",
  meta: {
    title: "系统用户管理",
    icon: "tree"
  },
  children: [{
    path: "sysuser",
    component: function component() {
      return import("@/views/sysuser/index");
    },
    name: "sysuser",
    meta: {
      title: "系统用户管理",
      icon: "tree",
      affix: false
    }
  }]
}, {
  path: "/dict",
  component: Layout,
  alwaysShow: true,
  name: "dict",
  meta: {
    title: "字典管理",
    icon: "tree"
  },
  children: [{
    path: "dict",
    component: function component() {
      return import("@/views/dict/index");
    },
    name: "dict",
    meta: {
      title: "字典管理",
      icon: "tree",
      affix: false
    }
  }]
},
/* {
  path: "/sensor",
  component: Layout,
  alwaysShow: true,
  name: "sensor",
  meta: {
    title: "传感器管理",
    icon: "tree",
  },
  children: [
    {
      path: "sensor",
      component: () => import("@/views/sensor/index"),
      name: "sensor",
      meta: { title: "传感器管理", icon: "tree", affix: false }
    },
  ]
},
{
  path: "/report",
  component: Layout,
  alwaysShow: true,
  name: "report",
  meta: {
    title: "报表管理",
    icon: "tree",
  },
  children: [
    {
      path: "curvechart",
      component: () => import("@/views/report/curvechart"),
      name: "curvechart",
      meta: { title: "曲线图表", icon: "tree", affix: false }
    },
    {
      path: "analysis",
      component: () => import("@/views/report/analysis"),
      name: "analysis",
      meta: { title: "数据分析", icon: "tree", affix: false }
    },
    {
      path: "tabledata",
      component: () => import("@/views/report/tabledata"),
      name: "tabledata",
      meta: { title: "数据列表", icon: "tree", affix: false }
    },
  ]
},*/
// 404 page must be placed at the end !!!
{
  path: "*",
  redirect: "/404",
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;