import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/system/sys_user/login',
    method: 'post',
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: '/system/sys_user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function logout() {
  return request({
    url: '/system/sys_user/logout',
    method: 'post'
  });
}
export function fetchSysUserList(data) {
  return request({
    url: '/system/sys_user/list',
    method: 'post',
    data: data
  });
}
export function fetchSysUser(data) {
  return request({
    url: '/system/sys_user/detail',
    method: 'post',
    data: data
  });
}
export function deleteSysUser(data) {
  return request({
    url: '/system/sys_user/delete',
    method: 'post',
    data: data
  });
}
export function updateSysUser(data) {
  return request({
    url: '/system/sys_user/update',
    method: 'post',
    data: data
  });
}
export function createSysUser(data) {
  return request({
    url: '/system/sys_user/create',
    method: 'post',
    data: data
  });
}
export function fetchSysUserRoles(data) {
  return request({
    url: '/system/sys_user/roles',
    method: 'post',
    data: data
  });
}
export function updatePassword(data) {
  return request({
    url: '/system/sys_user/updatePassword',
    method: 'post',
    data: data
  });
}
export function getSysUser(data) {
  return request({
    url: '/system/sys_user/getUserInfo',
    method: 'post',
    data: data
  });
}
export function updateUserInfo(data) {
  return request({
    url: '/system/sys_user/updateSysUser',
    method: 'post',
    data: data
  });
}